<route>
{
  "meta": {
    "permission": [
      "currencies.add_currency", "currencies.change_currency", "currencies.delete_currency", "currencies.view_currency"
    ]
  }
}
</route>

<template>
  <v-container fluid>
    <v-card>
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(submit)">
          <i-toolbar :title="$tc('currency', 1)" :loading="load"></i-toolbar>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="6">
                <ValidationProvider
                  vid="code"
                  :name="$t('code')"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    name="code"
                    outlined
                    :error-messages="errors[0]"
                    v-model="currency.code"
                    color="secondary"
                    :label="$tc('code', 1)"
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="12" md="6">
                <ValidationProvider
                  vid="name"
                  :name="$t('name')"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    name="name"
                    outlined
                    :error-messages="errors[0]"
                    v-model="currency.name"
                    color="secondary"
                    :label="$tc('name', 1)"
                  />
                </ValidationProvider>
              </v-col>
            </v-row>
          </v-card-text>
        </form>
      </ValidationObserver>
    </v-card>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      load: false,
      currency: {
        name: '',
        code: ''
      }
    }
  },
  computed: {
    ...mapGetters({
      currencies: 'company/getCurrencies'
    })
  },
  methods: {
    submit() {
      this.load = true
      this.$api.currency
        .edit({
          pk: this.currency.pk,
          form: this.currency
        })
        .then(() => {
          this.$store.dispatch(
            'company/setCurrencies',
            this.currencies().map((element) => {
              if (element.pk === this.currency.pk) {
                return this.currency
              }
              return element
            })
          )
          this.$toast.success(
            `${this.$tc('currency', 1)} ${this.$tc('edited', 2)}`
          )
          this.$router.push({ name: 'currency' })
        })
        .finally(() => (this.load = false))
    },
    getCurrency() {
      this.$api.currency
        .show({
          pk: this.codification({ mode: 'decode', code: this.$route.params.pk })
        })
        .then((res) => {
          this.currency = res.data
          this.$route.meta.title = this.currency.name
        })
        .catch(() => this.$router.push({ name: 'currency' }))
    }
  },
  mounted() {
    this.getCurrency()
  }
}
</script>
